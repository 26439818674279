import React from 'react';
import styles from './DataDeletion.module.scss';

const DataDeletion = () => (
    <div className={styles.container}>
        <div className={styles.largeTitle}>
            Data deletion request
        </div>
        To delete your data from our database, please send an email to
        {' '}
        <a className={styles.link} href="mailto:support@littlebitofuniverse.com">
            support@littlebitofuniverse.com
        </a>
        ,
        {' '}
        <br />
        from the email address associated with your account. Please note that this action is irreversible.
        <br />
        Once your data is deleted, you will be notified via email. This action should take no longer than 5 working days.
    </div>
);

export default DataDeletion;
