import React from 'react';
import styles from './NavBar.module.scss';
import Logo from '../Logo';
import { Link } from 'react-router-dom';

const NavBar = () => (
    <div
        className={styles.container}
    >
        <div
            className={styles.navbarBackground}
        >
            <Link to="/">
                <Logo
                    style={{
                        width: '80px',
                        height: '80px',
                    }}
                />
            </Link>
        </div>
        <div
            className={styles.shadow}
        />
    </div>
);

export default NavBar;
