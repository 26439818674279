import React from 'react';
import styles from './CookiesPolicy.module.scss';

const CookiesPolicy = () => (
    <div className={styles.container}>
        <div className={styles.largeTitle}>
            Cookies policy
        </div>
        Little bit of universe does not use any analytics or tracking cookies.
        <br />
        We only use cookies that are necessary for propper website functionality and security.
        <br />
        By using this website, you agree to our use of cookies.
    </div>
);

export default CookiesPolicy;
