import React from 'react';
import styles from './HomeLandingPage.module.scss';
import PhoneFrame from '../assets/images/Iphone-14-Pro-frame.png';
import ScreenRecording from '../assets/videos/ScreenRec.mov';
import AndroidQRCode from '../assets/images/android-qr-code.png';
import IOSQRCode from '../assets/images/ios-qr-code.png';
import Background from '../components/layout/Background';

const features = [
    '\u2022 15 Workout guides',
    '\u2022 120 Workouts to fit your needs',
    '\u2022 240 Unique Video Exercises',
    '\u2022 Adjustable Difficulty',
    '\u2022 Home/Outdoor workouts',
    '\u2022 No phone touch during workouts',
    '\u2022 No equipment needed',
];

const HomeLandingPage = () => (
    <div className={styles.container}>
        <Background />
        <div
            className={styles.phoneContainer}
        >
            <img
                className={styles.phoneFrame}
                src={PhoneFrame}
            />
            <video
                className={styles.videoRec}
                autoPlay
                loop
                muted
                src={ScreenRecording}
            />
        </div>
        <div
            className={styles.contentContainer}
        >
            <div className={styles.title}>
                Welcome to Little
                <span className={styles.green}>bit</span>
                of
                <span className={styles.green}>Workout</span>
            </div>
            <div className={styles.description}>
                A simple app that will guide you on the journey to become healthy and fit.
                <br />
                <br />
                <div className={styles.rowContainer}>
                    <div
                        className={styles.qrCodeContainer}
                    >
                        <img
                            className={styles.qrCode}
                            src={AndroidQRCode}
                        />
                        <div
                            className={styles.qrCodeText}
                        >
                            {'Download from\nGoogle Play'}
                        </div>
                    </div>
                    <div
                        className={styles.featuresContainer}
                    >
                        <div className={styles.featuresTitle}>
                            App features:
                        </div>
                        {features.map((feature) => (
                            <div
                                key={feature}
                                className={styles.feature}
                            >
                                {feature}
                            </div>
                        ))}
                    </div>
                    <div
                        className={styles.qrCodeContainer}
                    >
                        <img
                            className={styles.qrCode}
                            src={IOSQRCode}
                        />
                        <div
                            className={styles.qrCodeText}
                        >
                            {'Download from\nApp Store'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default HomeLandingPage;
