import React, { useEffect } from 'react';
import styles from './App.module.scss';
import './input.css';
import AppNavigator from './navigation/AppNavigator';
import { ToastNotificationProvider } from './context/ToastNotificationContext';
import ToastNotificationCard from './components/cards/ToastNotificationCard';
import CookieBanner from './components/layout/CookieBanner';
import Footer from './components/layout/Footer';
import NavBar from './components/layout/NavBar';

function App() {
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl?.includes('heroku')) {
      window.location.href = 'https://littlebitofuniverse.com/';
    }
  }, []);

  return (
    <div className={styles.appContainer}>
      <ToastNotificationProvider>
        <ToastNotificationCard />
        <NavBar />
        <AppNavigator />
        <Footer />
      </ToastNotificationProvider>
      <CookieBanner />
    </div>
  );
}

export default App;
